// @flow

const colors = {
  blue: 'rgba(89, 154, 242, 1)',
  lightBlue: 'rgba(162, 198, 248, 1)',
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(79, 79, 79, 1)',
  grey: 'rgba(79, 79, 79, 0.87)',
  paleBlue: '#dbedff',
};

export default colors;
