import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Route, Switch, Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";

import HomePage from "./HomePage";
import SchedulerPage from "./SchedulerPage";
import AboutPage from "./AboutPage";
import PageAuth from "./PageAuth";
import PageMeetings from "./PageMeetings";

class RootImpl extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isLoggedIn, isLoaded, firebase, email } = this.props;

    return (
      <div className="Global">
        <div className="Navbar" style={{ zoom: 0.8 }}>
          <Link to="/">Create a meeting</Link>
          <div style={{ width: 20 }} />
          {isLoggedIn && (
            <Link to="/app" style={{ marginRight: 20 }}>
              Your meetings
            </Link>
          )}
          <Link to="/about">How it works</Link>
          {!isLoggedIn && isLoaded && (
            <div style={{ margin: "0 0 0 auto", display: "flex" }}>
              {/* <Link to="/login">Log In</Link>
              <div style={{ width: 20 }} />
              <Link to="/register">Sign Up</Link> */}
            </div>
          )}
          {isLoggedIn && (
            <div
              style={{
                margin: "0 0 0 auto",
                display: "flex",
                color: "white",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={firebase.logout}
            >
              Log Out
            </div>
          )}
        </div>
        <Switch>
          <Route
            exact
            path="/(login|register|auth|reset)/"
            component={PageAuth}
          />
          <Route exact path="/" render={(props) => <HomePage />} />
          <Route exact path="/about" render={(props) => <AboutPage />} />
          <Route
            exact
            path="/app"
            render={(props) =>
              isLoggedIn ? (
                <PageMeetings isLoggedIn={isLoggedIn} email={email} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/datamatch&:id&:email"
            render={(props) => (
              <SchedulerPage
                id={props.match.params.id}
                email={props.match.params.email}
                datamatch
              />
            )}
          />
          <Route
            exact
            path="/:id&:admin"
            // path="/:id"
            render={(props) => (
              <SchedulerPage
                id={props.match.params.id}
                admin={props.match.params.admin}
              />
            )}
          />
          <Route
            exact
            path="/:id"
            render={(props) => (
              <SchedulerPage id={props.match.params.id} admin={""} />
            )}
          />
          <Route exact path="/about" component={AboutPage} />
          {/*<Route 
            exact
            path="/:id" 
            render={props => 
              <SchedulerPage 
                id={props.match.params.id} 
                admin={""}
              />
            }
          />*/}
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoaded: state.firebase.auth.isLoaded && state.firebase.profile.isLoaded,
    isLoggedIn: state.firebase.auth.uid ? true : false,
    onboarded: state.firebase.profile.onboarded,
    emailVerified: state.firebase.auth.emailVerified,
    email: state.firebase.profile.email,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RootImpl);
