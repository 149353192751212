/** @jsx jsx **/

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { jsx, css } from "@emotion/core";

import Loading from "components/Loading";
import GoogleButton from "components/GoogleButton";
import Logo from "components/Logo";

const style = css``;

export default class PageLogin extends Component {
  state = { message: "", loading: false };

  onFormSubmit = (event) => {
    event.preventDefault();

    const email = this.refs.email.value;
    const password = this.refs.password.value;

    this.setState({ loading: true });

    this.props
      .loginUser({ email, password })
      .then((data) => {
        // Mixpanel.identify(email);
        // Mixpanel.track("user_login", { email: email });
      })
      .catch((error) => {
        this.setState({ message: error.message, loading: false });
      });
  };

  loginWithProvider = (provider) => {
    this.setState({ loading: true });

    this.props
      .loginUser({ provider })
      .then((data) => {
        let email = data.user.user.email;
        // Mixpanel.identify(email);
        // Mixpanel.track("user_login", { email: email });
      })
      .catch((error) => {
        this.setState({ message: error.message, loading: false });
      });
  };

  render() {
    const { loading, message } = this.state;

    return (
      <form className="page-login" onSubmit={this.onFormSubmit} css={style}>
        <h2>LOGIN</h2>
        <input type="email" ref="email" placeholder="Email" />
        <input type="password" ref="password" placeholder="Password" />
        {!loading ? (
          <Fragment>
            <button type="submit">LOGIN</button>
            {message && <div className="warning">{message}</div>}
          </Fragment>
        ) : (
          <Loading
            style={{ height: 50, background: "#7d82b8", borderRadius: 8 }}
            color="white"
            width="20%"
            height={"auto"}
          />
        )}
        {/*<GoogleButton
          onClick={() => {
            !loading && this.loginWithProvider("google");
          }}
        />*/}
        {/*<div className="terms">
          By logging in you agree to the
          <br />
          <a href={"/terms"} target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={"/privacy"} target="_blank">
            Privacy Policy
          </a>
        </div>*/}
        <Link to="/reset">Forgot password? Reset</Link>
        <Link to="/register">Create an account</Link>
      </form>
    );
  }
}
