import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";

import HomePageLayout from "./layout.js";

class HomePage extends React.Component {
  render() {
    return <HomePageLayout {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoggedIn: state.firebase.auth.uid ? true : false,
    addSchedule: async (data) => {
      let newRef = await props.firestore.add(
        { collection: "schedules" },
        {
          ...data,
          schedule: [],
          people: [],
          members: state.firebase.profile.email
            ? [...data.members, state.firebase.profile.email]
            : [],
          updated: new Date().toString(),
          time: new Date(),
          main: state.firebase.profile.email || "",
        }
      );

      // if (state.firebase.auth.uid) {
      //   props.firebase.functions().httpsCallable("emailToMembers")(
      //     data.name,
      //     [...data.members, state.firebase.profile.email],
      //     data.admin,
      //     newRef.id
      //   );
      // }

      props.history.push("/" + newRef.id + "&" + data.admin);
      // props.history.push('/' + newRef.id)
    },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  withRouter,
  firebaseConnect(),
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HomePage);
