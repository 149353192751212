import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  firestoreConnect,
  firebaseConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { Modal } from "react-bootstrap";
import moment from "moment";

const numToDay = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

class PageMeetings extends Component {
  state = {};
  render() {
    const { meetings, email, deleteMeeting, leaveMeeting } = this.props;

    if (!isLoaded(meetings)) {
      return (
        <div style={{ textAlign: "center", marginTop: 100 }}>Loading...</div>
      );
    }

    if (isEmpty(meetings)) {
      return (
        <div style={{ textAlign: "center", marginTop: 100 }}>
          No meetings... Go create one <Link to="/">here</Link>
        </div>
      );
    }

    return (
      <div
        style={{
          width: "calc(100% - 40px)",
          margin: "20px auto",
          maxWidth: 700,
        }}
      >
        <h3>Your Meetings</h3>
        <br />
        {meetings.map(
          ({
            id,
            name,
            updated,
            admin,
            main,
            reoccuring,
            reoccuringType,
            weeklyInterval,
            monthlyInterval,
            members,
          }) => (
            <div
              style={{
                marginBottom: 20,
                border: "1px solid lightgrey",
                padding: 14,
                borderRadius: 8,
                zoom: 0.8,
              }}
            >
              <div>
                <Link to={"/" + id + "&" + admin}>
                  <div style={{ fontSize: 24 }}>
                    {name} <i className="fas fa-arrow-right"></i>
                  </div>
                </Link>
                <div>{moment(updated).format("MMM Do YYYY")}</div>
              </div>
              {reoccuring && (
                <div>
                  {reoccuringType === "weekly" && (
                    <div>
                      Reoccuring every <b>week</b> on{" "}
                      <b>{numToDay[weeklyInterval]}</b> with{" "}
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {members.map((member, index) => (
                          <b>
                            {index !== 0 && ", "}
                            {member}
                          </b>
                        ))}
                      </div>
                    </div>
                  )}
                  {reoccuringType === "monthly" && (
                    <div>
                      Reoccuring every <b>month</b> on day{" "}
                      <b>{monthlyInterval}</b> with{" "}
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {members.map((member, index) => (
                          <b>
                            {index !== 0 && ", "}
                            {member}
                          </b>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontWeight: "bold",
                  display: "inline-block",
                }}
                onClick={() => {
                  this.setState({ modal: true, id, main });
                }}
              >
                {main === email ? "Delete Meeting" : "Leave Meeting"}
              </div>
            </div>
          )
        )}
        <Modal
          show={this.state.modal}
          onHide={() => this.setState({ modal: false, id: null, main: null })}
        >
          <div style={{ padding: "20px 10px" }}>
            {" "}
            Are you sure you want to{" "}
            {this.state.main === email ? "delete" : "leave"} this meeting?
          </div>
          <button
            style={{ borderRadius: 0 }}
            onClick={() => {
              this.setState({ modal: false });
              if (this.state.main === email) {
                deleteMeeting(this.state.id);
              } else {
                leaveMeeting(this.state.id);
              }
            }}
          >
            Confirm
          </button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meetings: state.firestore.ordered.meetings,
    // email: state.firebase.auth.email,
    deleteMeeting: (id) => {
      props.firestore.delete({ collection: "schedules", doc: id });
    },
    leaveMeeting: (id) => {
      props.firestore.update(
        { collection: "schedules", doc: id },
        { members: props.firestore.FieldValue.arrayRemove(props.email) }
      );
    },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => {
    if (props.isLoggedIn && props.email) {
      return [
        {
          collection: "schedules",
          where: [["members", "array-contains", props.email]],
          orderBy: ["time", "desc"],
          storeAs: "meetings",
          // limit: 24
        },
      ];
    } else {
      return [];
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageMeetings);
