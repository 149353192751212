/** @jsx jsx **/

import React, { PureComponent } from "react";
import ReactLoading from "react-loading";
import { jsx, css } from "@emotion/core";

const style = css``;

export default class Loading extends PureComponent {
  render() {
    return (
      <div
        css={style}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...this.props.style,
        }}
      >
        <ReactLoading
          className={"Loading"}
          type={this.props.type ? this.props.type : "bubbles"}
          width={this.props.width || "30%"}
          height={this.props.height || 100}
          color={this.props.color || "black"}
        />
      </div>
    );
  }
}
