/** @jsx jsx **/

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { jsx, css } from "@emotion/core";

import Logo from "components/Logo";
import Loading from "components/Loading";

const style = css``;

export default class PageConfirmEmail extends Component {
  state = {
    resent: false,
    loading: false,
    verfied: false,
    error: this.props.oobCode ? (
      "Verifying your email..."
    ) : (
      <span>
        A verification email has been sent to your email address,{" "}
        <b>{this.props.email}</b>.
      </span>
    ),
  };

  verifyEmail = () => {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (values.oobCode) {
      this.props.firebase
        .auth()
        .applyActionCode(values.oobCode)
        .then(() => {
          this.setState({ verified: true, error: "Verified!" });
          if (this.props.isLoggedIn) {
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({ error: err.message });
        });
    }
  };

  resendEmail = () => {
    this.setState({ loading: true });
    this.props.firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        this.setState({
          resent: true,
          loading: false,
          error: "Verification email resent. Please check your inbox.",
        });
      })
      .catch((err) => {
        this.setState({ error: err.message, loading: false });
      });
  };

  componentDidMount() {
    this.verifyEmail();
    if (this.props.emailVerified) {
      this.props.history.push("/app");
    }
  }

  renderConfirm = () => {
    if (this.props.isLoggedIn) {
      if (this.props.emailVerified) {
        return (
          <div>
            You're verified!{" "}
            <Link to="/app" style={{ fontFamily: "apercu", color: "black" }}>
              Click here get started!
            </Link>
          </div>
        );
      }

      if (this.state.loading) {
        return (
          <Loading
            style={{ height: 50, background: "#7d82b8", borderRadius: 8 }}
            color="white"
            width="20%"
            height={"auto"}
          />
        );
      } else {
        if (!this.state.resent) {
          return (
            <button type="button" onClick={this.resendEmail}>
              resend verification email
            </button>
          );
        }
      }
    } else {
      return (
        <div>
          {this.state.verified ? (
            <div>
              You've been verified! Log back in by clicking{" "}
              <Link
                to="/login"
                style={{ fontFamily: "apercu", color: "black" }}
              >
                here
              </Link>{" "}
              to get started!
            </div>
          ) : (
            <div className="warning">{this.state.error}</div>
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <form className="page-confirm" css={style}>
        <h2>CONFIRM</h2>
        {this.state.error && <div className="warning">{this.state.error}</div>}
        {this.renderConfirm()}
        {this.props.isLoggedIn && (
          <button
            type="button"
            onClick={this.props.logoutUser}
            style={{
              background: "none",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 14,
            }}
          >
            LOGOUT
          </button>
        )}
      </form>
    );
  }
}
