/** @jsx jsx **/

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";

// import { Mixpanel } from "utils/mixpanel";
import GoogleButton from "components/GoogleButton";
import Loading from "components/Loading";
import Logo from "components/Logo";

const style = css``;

export default class PageRegister extends Component {
  state = {
    message: "",
    loading: false,
  };

  onFormSubmit = (event) => {
    event.preventDefault();

    const email = this.refs.email.value;
    const password = this.refs.password.value;
    const confirmPassword = this.refs["confirm-password"].value;

    this.setState({ loading: true });

    if (password === confirmPassword) {
      this.props
        .registerUser({ email, password })
        .then((data) => {
          this.props.firebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              console.log("verification email sent");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          this.setState({ message: error.message, loading: false });
        });
      // Mixpanel.alias(email);
      // Mixpanel.track("user_signup", { email: email });
    } else {
      this.setState({ message: "Passwords do not match", loading: false });
    }
  };

  loginWithProvider = (provider) => {
    this.setState({ loading: true });
    const updateProfile = this.props.updateProfile;

    this.props
      .loginUser({ provider })
      .then((data) => {
        const email = data.user.user.email;
        updateProfile(email);

        // Mixpanel.alias(email);
        // Mixpanel.track("user_signup", { email: email });
      })
      .catch((error) => {
        this.setState({ message: error.message, loading: false });
      });
  };

  render() {
    const { loading, message } = this.state;

    return (
      <form className="page-register" onSubmit={this.onFormSubmit} css={style}>
        <h2>SIGN UP</h2>
        <input type="email" ref="email" placeholder="Email" />
        <input type="password" ref="password" placeholder="Password" />
        <input
          type="password"
          ref="confirm-password"
          placeholder="Confirm Password"
        />
        {!loading ? (
          <Fragment>
            <button type="submit">SIGN UP</button>
            {this.state.message && <div className="warning">{message}</div>}
          </Fragment>
        ) : (
          <Loading
            style={{ height: 50, background: "#7d82b8", borderRadius: 8 }}
            color="white"
            width="20%"
            height={"auto"}
          />
        )}
        {/*<GoogleButton
          onClick={() => {
            !loading && this.loginWithProvider("google");
          }}
        />*/}
        {/*<div className="terms">
          By signing up you agree to the
          <br />
          <Link to="/terms">Terms of Service</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </div>*/}
        <Link to="/login">Already have an account? Log in</Link>
        <Link to="/reset">Forgot password? Reset</Link>
      </form>
    );
  }
}
