import * as React from "react";
import moment from "moment";
import ScheduleSelector from "components/ScheduleSelector";
import Viewer from "./components/Viewer";
import { Link } from "react-router-dom";
import { Mixpanel } from "Mixpanel";
import Links from "./components/Links";
import Scheduler from "./components/Scheduler";
import PollEdit from "./components/PollEdit";
import AdSense from "react-adsense";

export default class SchedulerPageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: !this.props.datamatch
        ? {}
        : this.props.schedule.people[this.props.email.split(".").join("")] ||
          {},
      name: !this.props.datamatch ? "" : this.props.email.split(".").join(""),
      get: false,
      type: "you",
      width: 0,
      newOption: "",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  componentDidMount() {
    this.forceUpdate();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    Mixpanel.track("Event Viewed", { id: this.props.schedule.admin });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleChange = (newSchedule) => {
    this.setState(
      { schedule: newSchedule },
      this.props.updateSchedule({ ["people." + this.state.name]: newSchedule })
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let dates = nextProps.schedule.dates.map((date) => {
      return new Date(date);
    });

    return { ...prevState, dates };
  }

  componentDidUpdate(props, state) {
    if (this.state.name.length > 0 && this.state.name !== state.name) {
      this.setState({
        schedule: props.schedule.people[this.state.name]
          ? props.schedule.people[this.state.name]
          : {},
        get: true,
      });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  onFormSubmit = (event) => {
    event.preventDefault();

    const name = this.refs.name.value;
    this.setState({ name });
    Mixpanel.track("Name Added", { id: this.props.schedule.admin });
  };

  addOption = () => {
    if (
      this.state.newOption.length > 0 &&
      !this.props.schedule.options.includes(this.state.newOption)
    ) {
      this.props.updateSchedule({
        options: [...this.props.schedule.options, this.state.newOption],
      });
      this.setState({ newOption: "" });
    }
  };

  selectOption = (option) => {
    let selected = this.props.schedule.selected[this.state.name]
      ? [...this.props.schedule.selected[this.state.name]]
      : [];

    if (selected.includes(option)) {
      for (var i = 0; i < selected.length; i++) {
        if (selected[i] === option) {
          selected.splice(i, 1);
        }
      }
    } else {
      selected.push(option);
    }

    this.props.updateSchedule({
      ["selected." + this.state.name]: selected,
    });
  };

  render() {
    const schedule = this.state.schedule;
    const admin =
      this.props.schedule.admin === this.props.admin || this.props.datamatch;
    var width = this.props.datamatch ? 1100 : 800;
    // console.log(width)
    // const admin = true
    return (
      <div
        className="Schedule"
        style={{ zoom: 0.8 }}
        key={this.props.schedule.id}
      >
        <br />
        <div style={{ textAlign: "center", padding: "0 10px" }}>
          <h3 style={{ textAlign: "center", marginBottom: 25 }}>
            {this.props.schedule.name}
          </h3>
          {admin && !this.props.datamatch && (
            <Links
              width={this.state.width}
              adjust_width={width}
              admin={this.props.admin}
              id={this.props.id}
            />
          )}
          {/* <Link to="/">create a new event</Link> */}
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          TIMEZONE: <b>{Intl.DateTimeFormat().resolvedOptions().timeZone}</b>
        </div>
        <br />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            background:
              this.state.width < width && this.props.datamatch
                ? "rgb(242, 242, 242)"
                : "inherit",
          }}
        >
          <div
            className="Half"
            style={
              this.props.datamatch
                ? {
                    background: "rgb(242, 242, 242)",
                    marginLeft: 15,
                    marginRight: 15,
                  }
                : {}
            }
          >
            {this.state.width >= width && (
              <div>
                <br />
                <h5>Your Schedule</h5>
              </div>
            )}
            {this.state.width < width && admin && (
              <div style={{ maxWidth: 360, margin: "0 auto", marginTop: 25 }}>
                <button
                  className={
                    this.state.type !== "you"
                      ? "typeSelector selected"
                      : "typeSelector"
                  }
                  onClick={() => {
                    this.setState({ type: "you" });
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  Your Schedule
                </button>
                <button
                  className={
                    this.state.type !== "you"
                      ? "typeSelector"
                      : "typeSelector selected"
                  }
                  onClick={() => this.setState({ type: "everyone" })}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Everyone
                </button>
              </div>
            )}
            {this.state.width >= width || this.state.type === "you" ? (
              <div>
                {this.state.name.length > 0 ? (
                  <div>
                    <br />
                    <Scheduler
                      schedule={this.props.schedule}
                      stateSchedule={this.state.schedule}
                      handleChange={this.handleChange}
                      dates={this.state.dates}
                      id={this.props.id}
                    />
                    <br />
                    <PollEdit
                      schedule={this.props.schedule}
                      name={this.state.name}
                      addOption={this.addOption}
                      handleInputChange={this.handleInputChange}
                      newOption={this.state.newOption}
                      selectOption={this.selectOption}
                      id={this.props.id}
                    />
                    <br />
                    <br />
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <form onSubmit={this.onFormSubmit}>
                      <input
                        style={{
                          width: "100%",
                          maxWidth: 360,
                          textAlign: "center",
                        }}
                        type="name"
                        ref="name"
                        placeholder="Name"
                      />
                      <br />
                      <br />
                      <button
                        style={{ width: "100%", maxWidth: 360 }}
                        type="submit"
                      >
                        Start
                      </button>
                      <br />
                      <br />
                    </form>
                    <div style={{ maxWidth: 300, margin: "0 auto" }}>
                      Enter your name and drag over your available times. <br />
                      <br />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <br />
                {this.state.type === "everyone" &&
                this.state.width < width &&
                admin ? (
                  <Viewer {...this.props} mobile />
                ) : (
                  <div />
                )}
              </div>
            )}
          </div>
          {admin && (
            <div
              className={this.props.datamatch ? "Half dmhidden" : "Half hidden"}
              style={
                this.props.datamatch
                  ? {
                      background: "rgb(242, 242, 242)",
                      marginLeft: 15,
                      marginRight: 15,
                    }
                  : {}
              }
            >
              <br />
              <h5>
                {this.props.datamatch
                  ? "Both Your Schedules"
                  : "Everyone's Schedule"}
              </h5>
              <br />
              {this.state.width >= width ? <Viewer {...this.props} /> : <div />}
              <div style={{ height: 31 }} />
            </div>
          )}
        </div>
        {this.props.datamatch && (
          <div
            style={{
              maxWidth: 1030,
              margin: "0 auto",
              textAlign: this.state.width < width ? "center" : "left",
            }}
          >
            <br />
            <a target="_blank" href="https://happytomeet.com">
              <img src={require("assets/logo.png")} style={{ width: 200 }} />
            </a>
            {/*<div>Link to share schedule</div>
            <input
              onFocus={e => e.target.select()}
              value={window.location.href}
              style={{ 
                background : "rgb(242, 242, 242)", 
                width: 300, 
                borderRadius: 0,
                borderWidth: 0,
                // margin: this.state.width < width ? "0 auto" : "auto auto"
              }}
            />*/}
          </div>
        )}
        {!this.props.datamatch && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <br />
            <a target="_blank" href="https://happytomeet.com">
              <img src={require("assets/logo.png")} style={{ width: 200 }} />
            </a>
          </div>
        )}
        <br />
        <br />
      </div>
    );
  }
}
