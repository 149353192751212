/** @jsx jsx **/

import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { jsx, css } from "@emotion/core";

import PageLogin from "./components/PageLogin";
import PageRegister from "./components/PageRegister";
import PagePasswordReset from "./components/PagePasswordReset";
import PageConfirmEmail from "./components/PageConfirmEmail";
import Logo from "components/Logo";

import { PrivateRoute, PublicRoute } from "components/PrivateRoute";
import queryString from "query-string";

const style = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > form {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    width: 100%;
    max-width: 300px;

    button,
    > input {
      width: 100%;
      height: 44px;
      padding: 0 10px;
      font-size: 14px;
      border: 1px solid rgba(59, 59, 59, 0.1);
    }

    input {
      transition: border 0.5s ease;
      &:focus {
        border: 1px solid rgba(59, 59, 59, 0.5);
      }
    }

    button {
      height: 50px;
    }

    > .warning {
      background: #ffb2aeb8;
      color: #9b231d;
      font-weight: 600;
      padding: 10px;
      font-size: 12px;
    }

    > .alert-messsage {
      background: #b4ecb48c;
      color: #3aa33a;
      font-weight: 600;
      padding: 10px;
      font-size: 12px;
    }

    > .terms {
      padding: 10px;
      background: rgb(243, 242, 245);
      font-size: 12px;

      > a {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    > button {
      font-size: 14px;
      font-weight: bold;
    }

    > a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

class PageAuthLayout extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    return (
      <div className="page-auth" css={style}>
        <br />
        <br />
        <Logo />
        <br />
        <br />
        <Switch>
          <PublicRoute
            {...this.props}
            exact
            path="/login"
            component={PageLogin}
            isLoggedIn={this.props.isLoggedIn}
          />
          <PublicRoute
            {...this.props}
            exact
            path="/register"
            component={PageRegister}
            isLoggedIn={this.props.isLoggedIn}
          />
          <PrivateRoute
            {...this.props}
            exact
            path="/app"
            component={PageConfirmEmail}
            isLoggedIn={this.props.isLoggedIn}
          />
          <Route
            path="/(auth|reset)"
            render={() => {
              if (values.mode === "verifyEmail") {
                return (
                  <Route
                    path="/auth"
                    render={() => {
                      return (
                        <PageConfirmEmail
                          {...this.props}
                          oobCode={values.oobCode}
                          isLoggedIn={this.props.isLoggedIn}
                          emailVerified={this.props.emailVerified}
                        />
                      );
                    }}
                  />
                );
              } else {
                //else if (values.mode === "resetPassword"){
                return (
                  <PublicRoute
                    {...this.props}
                    path="/(auth|reset)"
                    component={PagePasswordReset}
                    isLoggedIn={this.props.isLoggedIn}
                  />
                );
              }
            }}
          />
        </Switch>
        <br />
        <br />
      </div>
    );
  }
}

export default PageAuthLayout;
