import * as React from 'react';
import moment from 'moment';
import ScheduleSelector from 'components/ScheduleSelector';
import ReactCursorPosition from 'react-cursor-position';

export default class SchedulerPageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: null,
      peopleByDate: new Set(),
      allSchedule: {},
      people: 0,
      dateToPeople: {},
      count: 0,
      optionToPeople: {},
      totalOptions: 0,
      display: 'none',
      x: 0,
      y: -300,
      choices: 'none',
    };
    this.someRefName = React.createRef();
    this.float = React.createRef();
    this.options = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let allSchedule = {};
    let people = 0;
    let dateToPeople = {};
    Object.keys(nextProps.schedule.people).map(person => {
      people += 1;
      allSchedule = { ...allSchedule, ...nextProps.schedule.people[person] };
      Object.keys(nextProps.schedule.people[person]).map(date => {
        if (dateToPeople[date]) {
          dateToPeople[date].add(person);
        } else {
          dateToPeople[date] = new Set([person]);
        }
      });
    });

    let dates = nextProps.schedule.dates.map(date => {
      return new Date(date);
    });

    let optionToPeople = {};
    let totalOptions = 0;
    Object.keys(nextProps.schedule.selected).map(person => {
      nextProps.schedule.selected[person].map(option => {
        totalOptions += 1;
        if (optionToPeople[option]) {
          optionToPeople[option].add(person);
        } else {
          optionToPeople[option] = new Set([person]);
        }
      });
    });

    return {
      ...prevState,
      people,
      allSchedule,
      dateToPeople,
      dates,
      optionToPeople,
      totalOptions,
    };
  }

  renderFloat = unavailable => {
    return (
      <div
        ref={this.float}
        style={{
          position: this.props.mobile ? 'absolute' : 'absolute',
          display: this.state.display,
          background: 'rgba(256, 256, 256, 0.6)',
          width: 'calc(100% - 40px)',
          padding: '0 10px',
          pointerEvents: 'none',
          top: this.state.y - (this.props.mobile ? 430 : 330),
          left: 0,
          right: 0,
          margin: '0 auto',
        }}
      >
        <br />
        <span>
          <b>
            {this.state.count} / {this.state.people}
          </b>{' '}
          people are available at{' '}
        </span>
        <br />
        {this.state.time
          ? moment(this.state.time).format('LT - MMMM DD, YYYY')
          : ''}
        <br />
        <div
          style={{
            postion: 'absolute',
            top: 20,
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: 350,
            margin: '0 auto',
            paddingTop: 20,
          }}
        >
          <div>
            <u>available</u>
            <br />
            {this.state.peopleByDate &&
              [...this.state.peopleByDate].map(person => (
                <span>
                  <b>{person}</b>
                  <br />
                </span>
              ))}
          </div>
          <div>
            <u>unavailable</u>
            <br />
            {unavailable.map(person => (
              <span>
                <b>{person}</b>
                <br />
              </span>
            ))}
            <br />
          </div>
        </div>
        <br />
      </div>
    );
  };

  _onMouseMove(e) {
    var bounds = e.target.getBoundingClientRect();
    let y = e.screenY + window.pageYOffset;
    y =
      y <
      this.someRefName.current.clientHeight / 2 +
        this.someRefName.current.offsetTop +
        100
        ? y
        : y - this.float.current.clientHeight;
    this.setState({
      y: y,
      display: 'block',
    });
  }

  onOptionsMouseMove(e) {
    this.setState({
      screenX: e.screenX,
      screenY: e.screenY,
      choices: 'block',
    });
  }

  render() {
    const unavailable = this.state.peopleByDate
      ? Object.keys(this.props.schedule.people).filter(
          person => !this.state.peopleByDate.has(person)
        )
      : Object.keys(this.props.schedule.people);

    let largest = Object.keys(this.props.schedule.selected).length;

    return (
      <div ref={this.someRefName} style={{ position: 'relative' }}>
        {this.renderFloat(unavailable)}
        <div
          // ref={this.someRefName}
          onPointerMove={this._onMouseMove.bind(this)}
          onPointerLeave={() => this.setState({ display: 'none' })}
        >
          <ScheduleSelector
            dateFormat={
              this.props.schedule.type === 'week' ? 'ddd' : 'MMM DD ddd'
            }
            selection={this.state.allSchedule}
            minTime={this.props.schedule.minTime}
            maxTime={this.props.schedule.maxTime}
            margin={0}
            length={0.25}
            selectionScheme={'square'}
            display
            stackedDate
            onHover={(time, count, sample) => {
              // console.log("new", time, sample)
              this.setState({
                time,
                peopleByDate: this.state.dateToPeople[time.getTime()],
                count: this.state.dateToPeople[time.getTime()]
                  ? this.state.dateToPeople[time.getTime()].size
                  : 0,
              });
            }}
            id={this.props.id}
            rangeDates={this.state.dates}
            renderDateCell={(time, selected, refSetter, count) => {
              var border;
              switch (time.getMinutes()) {
                case 15:
                  border = '1px dashed lightgrey';
                  break;
                case 45:
                  border = '1px solid lightgrey';
                  break;
                default:
                  border = '0 solid lightgrey';
                  break;
              }

              return (
                <div
                  className={'block' + (selected ? ' selected' : '')}
                  ref={refSetter}
                  style={{
                    border: '0px solid white',
                    borderLeft: '1px solid lightgrey',
                    borderBottom: border,
                    background:
                      'rgba(125, 130, 184, ' +
                      (this.state.dateToPeople[time.getTime()]
                        ? this.state.dateToPeople[time.getTime()].size /
                          this.state.people
                        : 0) +
                      ')',
                  }}
                ></div>
              );
            }}
          />
        </div>
        <br />
        {/*<div 
            style={{
              // display: this.state.choices, 
              position:"absolute", 
              // top: this.state.screenY,
              // left: this.state.screenX,
            }}
          >
            test
          </div>*/}
        <div ref={this.options}>
          {this.props.schedule.options.map(option => {
            let percent = Math.round(
              (this.state.optionToPeople[option]
                ? this.state.optionToPeople[option].size /
                  (largest > 0 ? largest : 1)
                : 0) * 100
            );
            return (
              <div
                // onPointerMove={this.onOptionsMouseMove.bind(this)}
                // onPointerLeave={()=>this.setState({choices: "none"})}
                key={option}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: percent > 10 ? 'white' : '#3b3b3b',
                  maxWidth: 360,
                  padding: 10,
                  margin: '5px auto 0px auto',
                  border: '1px solid rgba(125, 130, 184, 0.3)',
                  borderRadius: 10,
                  background:
                    'linear-gradient(to right, #7D82B8 ' +
                    String(percent) +
                    '%, white 0%)',
                }}
              >
                <span
                  style={{
                    color: percent > 10 ? 'white' : '#3b3b3b',
                    maxWidth: '85%',
                    overflow: 'hidden',
                  }}
                >
                  {option}
                </span>
                <span style={{ color: percent > 90 ? 'white' : '#3b3b3b' }}>
                  {this.state.optionToPeople[option]
                    ? this.state.optionToPeople[option].size
                    : 0}{' '}
                  / {largest}
                </span>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}
