// @flow

import isBefore from 'date-fns/is_before';
import startOfDay from 'date-fns/start_of_day';

import * as dateUtils from '../date-utils';

const square = (
  selectionStart: ?Date,
  selectionEnd: ?Date,
  dateList: Array<Array<Date>>,
  selectionType,
  newSelection
) => {
  // let selected: Array<Date> = []
  let selection = { ...newSelection };
  if (selectionEnd == null) {
    if (selectionStart) selection = { [selectionStart]: true };
  } else if (selectionStart) {
    const dateIsReversed = isBefore(
      startOfDay(selectionEnd),
      startOfDay(selectionStart)
    );
    const timeIsReversed =
      dateUtils.getTime(selectionStart) > dateUtils.getTime(selectionEnd);

    // selected = dateList.reduce(
    //   (acc, dayOfTimes) =>
    //     acc.concat(
    //       dayOfTimes.filter(
    //         t =>
    //           selectionStart &&
    //           selectionEnd &&
    //           dateUtils.dateIsBetween(
    //             dateIsReversed ? selectionEnd : selectionStart,
    //             t,
    //             dateIsReversed ? selectionStart : selectionEnd
    //           ) &&
    //           dateUtils.timeIsBetween(
    //             timeIsReversed ? selectionEnd : selectionStart,
    //             t,
    //             timeIsReversed ? selectionStart : selectionEnd
    //           )
    //       )
    //     ),
    //   []
    // )
    dateList.map(date => {
      date.map(time => {
        var check =
          selectionStart &&
          selectionEnd &&
          dateUtils.dateIsBetween(
            dateIsReversed ? selectionEnd : selectionStart,
            time,
            dateIsReversed ? selectionStart : selectionEnd
          ) &&
          dateUtils.timeIsBetween(
            timeIsReversed ? selectionEnd : selectionStart,
            time,
            timeIsReversed ? selectionStart : selectionEnd
          );
        if (selectionType === 'add' && check) {
          selection[time.getTime()] = true;
        } else if (selectionType === 'remove' && check) {
          delete selection[time.getTime()];
        }
      });
    });
  }

  return selection;
};

export default square;
