/** @jsx jsx **/

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { jsx, css } from "@emotion/core";

import Logo from "components/Logo";
import Loading from "components/Loading";

const style = css``;

export default class PagePasswordReset extends Component {
  state = { message: "", sent: false, loading: false };

  onFormSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.refs.email.value;
    var auth = this.props.auth();
    auth
      .sendPasswordResetEmail(email)
      .then((data) => {
        this.setState({ sent: true, loading: false });
      })
      .catch((error) => {
        this.setState({ message: error.message, loading: false });
      });
  };

  onFormSubmitConfirm = (event, oobCode) => {
    event.preventDefault();
    this.setState({ loading: true });
    const password = this.refs.password.value;
    const confirmPassword = this.refs["confirm-password"].value;

    if (password === confirmPassword) {
      this.props
        .confirmPasswordReset(oobCode, password)
        .then((data) => {
          this.props.alert.show("Password reset confirmed!");
          this.setState({ loading: false });
          this.props.history.push("/login");
        })
        .catch((error) => {
          this.setState({ message: error.message, loading: false });
        });
    } else {
      this.setState({ message: "Passwords do not match" });
    }
  };

  renderForm = () => {
    return (
      <form onSubmit={this.onFormSubmit} css={style}>
        <h2>RESET PASSWORD</h2>
        {!this.state.sent ? (
          <Fragment>
            <input type="email" ref="email" placeholder="Email" />
            {!this.state.loading ? (
              <button type="submit">RESET</button>
            ) : (
              <Loading
                style={{ height: 50, background: "black" }}
                color="white"
                width="20%"
                height={"auto"}
              />
            )}
            {this.state.message && (
              <div className="warning">{this.state.message}</div>
            )}
          </Fragment>
        ) : (
          <div className="alert-messsage">
            Sent reset link! Check your email!
          </div>
        )}
        <Link to="/login">Already have an account? Log in</Link>
        <Link to="/register">Create an account</Link>
      </form>
    );
  };

  renderConfirm = (oobCode) => {
    return (
      <form onSubmit={(event) => this.onFormSubmitConfirm(event, oobCode)}>
        <h2>RESET PASSWORD</h2>
        <input type="password" ref="password" placeholder="Password" />
        <input
          type="password"
          ref="confirm-password"
          placeholder="Confirm Password"
        />
        {!this.state.loading ? (
          <button type="submit">RESET</button>
        ) : (
          <Loading
            style={{ height: 50, background: "#7d82b8", borderRadius: 8 }}
            color="white"
            width="20%"
            height={"auto"}
          />
        )}
        {this.state.message && (
          <div className="warning">{this.state.message}</div>
        )}
        <Link to="/login">Already have an account? Log in</Link>
        <Link to="/register">Create an account</Link>
      </form>
    );
  };

  render() {
    let values = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    return (
      <Fragment>
        {values.oobCode
          ? this.renderConfirm(values.oobCode)
          : this.renderForm()}
      </Fragment>
    );
  }
}
