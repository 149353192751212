import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import "./styles.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import { getFirebase } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import firebase from "firebase";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import { FIREBASE_CONFIG } from "./Firebase";

import rootReducer from "store/reducer";
import root from "store/saga";

require("dotenv").config();

firebase.initializeApp(FIREBASE_CONFIG);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

let middleware = [routerMiddleware(history), sagaMiddleware];

const rrfConfig = {
  userProfile: "users",
  // useFirestoreForProfile: true,
};

const store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(...middleware))
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

sagaMiddleware.run(root, getFirebase);

ReactDOM.render(
  <App store={store} rrfProps={rrfProps} />,
  document.getElementById("root")
);

serviceWorker.unregister();
