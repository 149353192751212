import * as React from "react";

export default class Logo extends React.Component {
  render() {
    return (
      <a href="https://happytomeet.com">
        <img src={require("assets/logo.png")} style={{ width: 200 }} />
      </a>
    );
  }
}
