import * as React from 'react';

export default class Links extends React.Component {
  handleFocus = event => {
    event.target.select();
  };

  render() {
    var link = window.location.origin + '/' + this.props.id;
    // var link = window.location.origin + '/' + this.props.id

    return (
      <div>
        {/*<p style={{overflowWrap: "break-word", marginBottom: 0}}>share the link: </p><br/>*/}
        <div
          style={{
            display: 'flex',
            margin: '0 auto',
            width: '100%',
            maxWidth: this.props.width >= this.props.adjust_width ? 860 : 360,
            flexWrap: 'wrap',
            justifyContent:
              this.props.width >= this.props.adjust_width
                ? 'space-between'
                : 'center',
          }}
        >
          {
            <div>
              <div
                style={{
                  fontSize: 10,
                  textAlign: 'left',
                  width: 360,
                  margin: '0 auto',
                }}
              >
                link to show only individual's response and hide others
              </div>
              <input
                onFocus={this.handleFocus}
                value={link + ''}
                style={{ maxWidth: 360 }}
              />
              {this.props.width < this.props.adjust_width ? (
                <div style={{ height: 10 }} />
              ) : (
                <div />
              )}
            </div>
          }
          <div>
            <div
              style={{
                // fontSize: 10,
                fontSize: 12,
                textAlign: 'left',
                width: 360,
                margin: '0 auto',
              }}
            >
              link to show everybody's responses
              {/* link to share */}
            </div>
            <input
              onFocus={this.handleFocus}
              // value={link + "&admin=" + this.props.admin}
              value={link + '&' + this.props.admin}
              style={{ maxWidth: 360 }}
            />
          </div>
        </div>
      </div>
    );
  }
}
