import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Route, Switch, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import moment from 'moment';
import SchedulerPageLayout from './layout';

class SchedulerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { set: false };
  }

  componentDidUpdate() {
    if (
      isLoaded(this.props.schedules) &&
      isEmpty(this.props.schedules) &&
      this.props.datamatch &&
      !this.state.set
    ) {
      this.props.setSchedule(genericSchedule);
      this.setState({ set: true });
    }
  }

  render() {
    if (!isLoaded(this.props.schedules)) {
      return (
        <div style={{ textAlign: 'center', marginTop: 100 }}>loading...</div>
      );
    }

    if (isEmpty(this.props.schedules)) {
      return (
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          schedule doesn't exist
        </div>
      );
    }

    return (
      <SchedulerPageLayout {...this.props} schedule={this.props.schedules[0]} />
    );
  }
}

var dates = [
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(-1, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(0, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(1, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(2, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(3, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(4, 'days')
    .toDate()
    .getTime(),
  moment()
    .local()
    .hour(9)
    .minute(0)
    .add(5, 'days')
    .toDate()
    .getTime(),
];

var genericSchedule = {
  dates: dates,
  name: '',
  minTime: 9,
  maxTime: 16.99,
  options: ['Annenberg'],
  people: {},
  schedule: [],
  selected: {},
  types: 'dates',
  generic: true,
};

const mapStateToProps = (state, props) => {
  return {
    id: props.id,
    email: props.email,
    schedules: state.firestore.ordered.schedules,
    updateSchedule: data => {
      props.firestore.update(
        { collection: 'schedules', doc: props.id },
        { ...data, updated: new Date().toString() }
      );
    },
    setSchedule: data => {
      props.firestore.set(
        { collection: 'schedules', doc: props.id },
        { ...data, updated: new Date().toString() }
      );
    },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'schedules',
      doc: props.id,
    },
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SchedulerPage);
