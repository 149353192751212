import * as React from "react";
import { connect } from "react-redux";
import PageAuthLayout from "./layout";
import { bindActionCreators, compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";

class PageAuth extends React.Component {
  render() {
    return <PageAuthLayout {...this.props} />;
  }
}

function mapStateToProps(state, props) {
  return {
    loginUser: props.firebase.login,
    updateProfile: props.firebase.updateProfile,
    registerUser: props.firebase.createUser,
    auth: props.firebase.auth,
    confirmPasswordReset: props.firebase.confirmPasswordReset,
    emailVerified: state.firebase.auth.emailVerified,
    logoutUser: props.firebase.logout,
    isLoggedIn: state.firebase.auth.uid ? true : false,
    email: state.firebase.auth.email,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  withAlert(),
  firebaseConnect(),
  firestoreConnect(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageAuth);
