import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Root from "./routes/Root";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import "./App.scss";

const options = {
  timeout: 3000,
  position: positions.BOTTOM_CENTER,
};

const App = ({ store, rrfProps }) => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <Route path="/" component={Root} />
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  </AlertProvider>
);

export default App;
