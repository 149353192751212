module.exports = {
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDrfZZmyyxCQ1txCyJdC0KS96-OA77RDxg',
    authDomain: 'beatmymeet2.firebaseapp.com',
    databaseURL: 'https://beatmymeet2.firebaseio.com',
    projectId: 'beatmymeet2',
    storageBucket: 'beatmymeet2.appspot.com',
    messagingSenderId: '911305089346',
    appId: '1:911305089346:web:cd094836b4152d5d',
  },
};
