/** @jsx jsx **/

import * as React from "react";
import { jsx, css } from "@emotion/core";
import ScheduleSelector from "components/ScheduleSelector";
import moment from "moment";
import ReactLoading from "react-loading";
import { Mixpanel } from "Mixpanel";
import AdSense from "react-adsense";
import { Link } from "react-router-dom";

const uuidv1 = require("uuid/v1");
const style = css`
  text-align: center;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  zoom: 0.8;
  display: grid;
  row-gap: 20px;
  padding-top: 20px;
  padding-bottom: 100px;

  @media only screen and (min-width: 80000000px) {
    column-gap: 24vw;
    margin: 0 auto;
    grid-template-columns: 360px 360px;
    grid-template-rows: min-content min-content min-content min-content min-content 1fr;
    max-width: calc(720px + 24vw);

    .website,
    .schedule-name {
      grid-column: 1/3;
    }

    .calendar,
    .selectors {
      grid-column: 1;
    }

    .calendar {
      grid-row: 4 / 8;
      margin-top: 20px;
    }

    .times {
      grid-row: 3;
    }

    .times,
    .reoccuring,
    .location,
    .submit,
    form {
      grid-column: 2;
    }
  }
`;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class HomePageLayout extends React.Component {
  state = {
    name: "",
    dates: {},
    type: "dates",
    month: moment().local(),
    minTime: "09:00",
    maxTime: "17:00",
    loading: false,
    options: [],
    reoccuring: false,
    reoccuringType: "weekly",
    weeklyInterval: moment().day(),
    monthlyInterval: Number(moment().format("D")),
    members: [],
  };

  componentDidMount() {
    Mixpanel.track("Home Page");
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  handleChange = (newSchedule) => {
    this.setState({ dates: newSchedule });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const name = this.state.name;
    const minComponents = this.state.minTime.split(":");
    const maxComponents = this.state.maxTime.split(":");

    const minTime = Number(minComponents[0]);
    const maxTime = Number(maxComponents[0]);

    var date_sort_asc = function(date1, date2) {
      // This is a comparison function that will result in dates being sorted in
      // ASCENDING order. As you can see, JavaScript's native comparison operators
      // can be used to compare dates. This was news to me.
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    var dates = Object.keys(this.state.dates)
      .filter((date) => this.state.dates[date])
      .map((date) => {
        var temp = moment(new Date(+date))
          .local()
          .hour(Number(minComponents[0]))
          .minute(Math.floor((Number(minComponents[1]) / 60) * 4) / 4)
          .toDate();

        return temp.getTime();
      });
    // .map(date => new Date(date))
    dates.sort(date_sort_asc);
    // var admin = uuidv1()
    var admin = getRandomInt(100, 999) + "";

    var options = true;
    this.state.options.map((option) => {
      if (option.length === 0) {
        options = false;
      }
    });
    if (
      name.length > 0 &&
      Object.keys(this.state.dates).length > 0 &&
      minTime < maxTime &&
      options
    ) {
      this.props.addSchedule({
        type: this.state.type,
        name: name,
        dates: dates,
        minTime: Number(
          Math.floor((minTime + Number(minComponents[1]) / 60) * 4) / 4
        ),
        maxTime:
          (
            Math.ceil((maxTime + Number(maxComponents[1]) / 60) * 4) / 4
          ).toFixed(2) - 0.01,
        admin: admin,
        options: Array.from(new Set(this.state.options)),
        selected: {},
        reoccuring: this.state.reoccuring,
        reoccuringType: this.state.reoccuringType,
        weeklyInterval: this.state.weeklyInterval,
        monthlyInterval: this.state.monthlyInterval,
        members: this.state.members,
      });

      Mixpanel.track("Event Created", { id: admin });
    } else {
      if (!options) {
        window.alert("an option is empty");
      } else if (name.length === 0) {
        window.alert("event needs a name");
      } else if (Object.keys(this.state.dates).length === 0) {
        window.alert("select a date!");
      } else if (minTime > maxTime) {
        window.alert("endtime needs to be after starttime");
      } else {
        window.alert("info missing");
      }
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div css={style}>
        <h3 className="website">{process.env.REACT_APP_NAME}</h3>
        <input
          className="schedule-name"
          value={this.state.name}
          required
          placeholder="Give this meeting a name"
          onChange={this.handleInputChange}
          style={{
            width: "100%",
            maxWidth: 360,
            margin: "0 auto",
            marginBottom: 20,
            fontSize: 22,
          }}
          name="name"
        />
        {/*<div>Start to finish hour</div>*/}
        <div
          className="times"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{"from "}</span>
          <input
            className={"TimeSelect"}
            type="time"
            value={this.state.minTime}
            required
            placeholder="min time"
            onChange={this.handleInputChange}
            name="minTime"
            style={{}}
          />
          <span>&#10230;</span>
          until
          <input
            className={"TimeSelect"}
            type="time"
            min={this.state.minTime}
            value={this.state.maxTime}
            required
            placeholder="max time"
            onChange={this.handleInputChange}
            name="maxTime"
            style={{ textAlign: "right" }}
          />
        </div>
        <div className="selectors">
          <button
            className={
              this.state.type !== "dates"
                ? "typeSelector selected"
                : "typeSelector"
            }
            onClick={() => {
              this.setState({
                type: "dates",
                dates: {},
                month: moment().local(),
              });
            }}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            dates
          </button>
          <button
            className={
              this.state.type !== "dates"
                ? "typeSelector"
                : "typeSelector selected"
            }
            onClick={() => this.setState({ type: "week", dates: {} })}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            week
          </button>
        </div>
        <div className="calendar">
          {this.state.type === "dates" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              {
                <button
                  className={"monthButton"}
                  onClick={() =>
                    this.setState({
                      month: this.state.month.subtract(1, "months"),
                    })
                  }
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
              }
              <span>{this.state.month.format("MMMM YYYY")}</span>
              {
                <button
                  className="monthButton"
                  onClick={() =>
                    this.setState({ month: this.state.month.add(1, "months") })
                  }
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              }
            </div>
          )}
          <div className={this.state.type}>
            <ScheduleSelector
              selection={this.state.dates}
              noTime
              minTime={9}
              maxTime={9}
              margin={0}
              dateFormat={this.state.type === "week" ? "ddd" : "ddd"}
              // length={1}
              noDate={this.state.type === "week" ? false : true}
              startDate={moment(this.state.month)
                .startOf("month")
                .startOf("week")
                .toDate()}
              numDays={42}
              selectionScheme={"square"}
              onChange={this.handleChange}
              rangeDates={
                this.state.type === "week"
                  ? [0, 1, 2, 3, 4, 5, 6].map((day) =>
                      moment()
                        .startOf("week")
                        .add(day, "days")
                        .toDate()
                    )
                  : null
              }
              renderDateCell={(time, selected, refSetter) => {
                return (
                  <div
                    className={"block" + (selected ? " selected" : "")}
                    ref={refSetter}
                  >
                    {this.state.type === "dates" && (
                      <div
                        style={{
                          opacity:
                            time.getMonth() === this.state.month.month()
                              ? 1
                              : 0.5,
                          color: selected ? "white" : "rgba(0, 0, 0, 0.85)",
                          fontWeight: moment().isSame(time, "day")
                            ? "900"
                            : "inherit",
                        }}
                      >
                        {time.getDate()}
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="location">
          <div>
            {Object.keys(this.state.options).map((key) => {
              return (
                <div style={{ position: "relative" }}>
                  <input
                    key={key}
                    value={this.state.options[key]}
                    placeholder={"option " + (Number(key) + 1)}
                    style={{ width: "100%", marginBottom: 5 }}
                    onChange={(event) => {
                      const value = event.target.value;
                      let options = [...this.state.options];
                      options[key] = value;
                      this.setState({ options: options });
                    }}
                  />
                  <i
                    className="far fa-times-circle"
                    style={{
                      position: "absolute",
                      right: 6,
                      top: 12,
                      fontSize: 24,
                      color: "#3b3b3b",
                      opacity: 0.5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let options = [...this.state.options];
                      options.splice(key, 1);
                      this.setState({
                        options: options,
                      });
                    }}
                  ></i>
                </div>
              );
            })}
          </div>
          <button
            onClick={() =>
              this.setState({
                options: [...this.state.options, ""],
              })
            }
          >
            {this.state.options.length > 0
              ? "add another location option"
              : "add a location poll"}
          </button>
        </div>
        {/* {!this.props.isLoggedIn && (
          <div
            className="reoccuring"
            style={{
              padding: 10,
              border: "1px solid lightgrey",
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={() => this.props.history.push("/register")}
          >
            Sign Up to create reoccurring meetings
          </div>
        )} */}
        {this.props.isLoggedIn && !this.state.reoccuring && (
          <div
            className="reoccuring"
            style={{
              padding: 10,
              border: "1px solid lightgrey",
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={() => this.setState({ reoccuring: true })}
          >
            Make this meeting reoccuring
          </div>
        )}
        {this.props.isLoggedIn && this.state.reoccuring && (
          <div
            style={{
              padding: 10,
              border: "1px solid lightgrey",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            Every{" "}
            <select
              value={this.state.reoccuringType}
              onChange={(event) =>
                this.setState({ reoccuringType: event.target.value })
              }
            >
              <option value="weekly">week</option>
              <option value="monthly">month</option>
            </select>{" "}
            on{" "}
            {this.state.reoccuringType === "weekly" && (
              <select
                value={this.state.weeklyInterval}
                onChange={(event) =>
                  this.setState({ weeklyInterval: event.target.value })
                }
              >
                <option value={0}>Sunday</option>
                <option value={1}>Monday</option>
                <option value={2}>Tuesday</option>
                <option value={3}>Wednesday</option>
                <option value={4}>Thursday</option>
                <option value={5}>Friday</option>
                <option value={6}>Saturday</option>
              </select>
            )}
            {this.state.reoccuringType === "monthly" && (
              <select
                value={this.state.monthlyInterval}
                onChange={(event) =>
                  this.setState({ monthlyInterval: event.target.value })
                }
              >
                {[...Array(31).keys()].map((day) => (
                  <option value={day + 1}>{day + 1}</option>
                ))}
              </select>
            )}{" "}
            <br />
            send a new schedule with <br /> incremented date range
            <br />
            <br />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ reoccuring: false })}
            >
              Disable Reoccuring
            </div>
          </div>
        )}
        {this.props.isLoggedIn ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (this.state.member) {
                this.setState({
                  members: [...this.state.members, this.state.member],
                  member: "",
                });
              }
            }}
          >
            <div
              className="members"
              style={{
                display: this.state.members.length > 0 ? "block" : "none",
              }}
            >
              {this.state.members.map((member) => (
                <div
                  style={{
                    height: 36,
                    marginBottom: 5,
                    padding: 5,
                    border: "1px solid lightgrey",
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{member}</div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      var array = [...this.state.members]; // make a separate copy of the array
                      var index = array.indexOf(member);
                      if (index !== -1) {
                        array.splice(index, 1);
                        this.setState({ members: array });
                      }
                    }}
                  >
                    x
                  </div>
                </div>
              ))}
            </div>
            <div style={{ display: "flex" }}>
              <input
                style={{ borderRadius: "8px 0 0 8px" }}
                type="email"
                placeholder="Add emails to send link to"
                value={this.state.member}
                onChange={(e) => this.setState({ member: e.target.value })}
              />
              <button style={{ width: 60, borderRadius: "0 8px 8px 0" }}>
                +
              </button>
            </div>
          </form>
        ) : (
          <div />
        )}
        {!this.state.loading ? (
          <button
            className="submit"
            onClick={this.onSubmit}
            style={{ fontWeight: "bold", marginTop: "auto", fontSize: 20 }}
          >
            create meeting!
          </button>
        ) : (
          <ReactLoading
            className="loading"
            type={"bubbles"}
            color={"#3b3b3b"}
            height={360}
            width={50}
          />
        )}
      </div>
    );
  }
}
