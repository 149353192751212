import * as React from 'react';

export default class PollEdit extends React.Component {
  render() {
    return (
      <div>
        {this.props.schedule.options.map(option => {
          let selected = this.props.schedule.selected[this.props.name]
            ? this.props.schedule.selected[this.props.name]
            : [];
          return (
            <button
              className={
                selected.includes(option) ? 'options selected' : 'options'
              }
              style={{ maxWidth: 360, marginTop: 5, height: 46 }}
              onMouseDown={() => this.props.selectOption(option)}
            >
              {option}
            </button>
          );
        })}
        <div
          style={{
            display: 'flex',
            maxWidth: 360,
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <input
            placeholder="add a polling option"
            value={this.props.newOption}
            name={'newOption'}
            style={{
              maxWidth: 360,
              marginTop: 5,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onChange={this.props.handleInputChange}
          />
          <button
            style={{
              width: 55,
              marginTop: 5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={this.props.addOption}
          >
            +
          </button>
        </div>
      </div>
    );
  }
}
