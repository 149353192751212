import * as React from 'react';
import ScheduleSelector from 'components/ScheduleSelector';

export default class Scheduler extends React.Component {
  render() {
    return (
      <ScheduleSelector
        dateFormat={this.props.schedule.type === 'week' ? 'ddd' : 'MMM DD ddd'}
        selection={this.props.stateSchedule}
        minTime={this.props.schedule.minTime}
        maxTime={this.props.schedule.maxTime}
        id={this.props.id}
        margin={0}
        length={0.25}
        stackedDate
        selectionScheme={'square'}
        onChange={this.props.handleChange}
        rangeDates={this.props.dates}
        renderDateCell={(time, selected, refSetter) => {
          var border;
          switch (time.getMinutes()) {
            case 15:
              border = '1px dashed lightgrey';
              break;
            case 45:
              border = '1px solid lightgrey';
              break;
            default:
              border = '0 solid lightgrey';
              break;
          }
          return (
            <div
              className={'block' + (selected ? ' selected' : '')}
              ref={refSetter}
              style={{
                border: 0,
                borderBottom: border,
                borderLeft: '1px solid lightgrey',
              }}
            ></div>
          );
        }}
      />
    );
  }
}
