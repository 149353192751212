import React, { Component } from 'react';

var width = 500;

export default class AboutPage extends Component {
  render() {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
      >
        <br />
        <div className="box">
          <h4>1. Create an event</h4>
          <img
            src={require('../assets/create.gif')}
            style={{ width: '100%', maxWidth: width }}
          />
          <br />
          <br />
        </div>
        <div className="box">
          <h4>2. Schedule Your Times</h4>
          <img
            src={require('../assets/schedule.gif')}
            style={{ width: '100%', maxWidth: width }}
          />
          <br />
          <br />
        </div>
        <div className="box">
          <h4>3. Vote on a Poll</h4>
          <img
            src={require('../assets/vote.gif')}
            style={{ width: '100%', maxWidth: width }}
          />
          <br />
          <br />
        </div>
        <div className="box">
          <h4>4. See Everyone's Availabilities</h4>
          <img
            src={require('../assets/availability.gif')}
            style={{ width: '100%', maxWidth: width }}
          />
          <br />
          <br />
        </div>
        <div className="box">
          <h4>5. Share with Others</h4>
          <img
            src={require('../assets/share.gif')}
            style={{ width: '100%', maxWidth: width }}
          />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
